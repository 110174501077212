<template>
  <div class="now-closed d-flex align-center justify-center">
      <div class="now-closed__bg">
        <img src="@/assets/images/background.png" class="now-closed__image" alt="" />
      </div>
      <div class="now-closed__content">
        <h1 class="now-closed__title">
          World Mobile Token Scan <br/>Now Closed
        </h1>
        <p class="now-closed__p">
          Keep up to date with the growth of the World Mobile and WMTx ecosystem
        </p>
        <div class="now-closed__options">
          <div class="now-closed__option">
            <a class="now-closed__a" href="https://airnode.worldmobile.net/explore/ecosystem/">Visit the Ecosystem Explorer</a>
            <p class="now-closed__p">Explore World Mobile’s pioneering DePIN network</p>
          </div>
          <div class="now-closed__option">
            <a class="now-closed__a">Visit the WMC Explorer (soon)</a>
            <p class="now-closed__p">Explore transactions and on-chain activity</p>
          </div>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {

  },
  beforeDestroy () {
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.now-closed {
  width: 100%;
  min-height: 100vh;
  background: #000;
  padding: 16px;
  display: flex;

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__option {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__a {
    display: flex;
    min-height: 60px;
    padding: 0px 42px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
background: #000;
    gap: 15px;
    color: #FFF;
text-align: center;
font-family: Manrope;
font-size: 22.5px;
font-style: normal;
font-weight: 700;
line-height: 27px; /* 120% */
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: space-between;

    @media (min-width: 959px) {
      flex-direction: row;
      gap: 3rem;
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 72px; /* 120% */
    margin: 0 0 32px;

    @media (min-width: 959px) {
      font-size: 72px;
       margin: 0 0 40px;
    }
  }

  &__text {
    color: white;
  }

  &__p {
    color: #FFF;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }
}

</style>
